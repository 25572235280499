/* Variable CSS */
:root {
    --background: #F6F5F6;
    --background-dark: #F4F1ED;
    --background-blue: #C2D8E5;
    /* primary */
    --primary: #FFFFFF;
    --primary-light: #d0d0d0;
    --primary-dark: #7f7f7f;
    --yellow: #FFED00;
    --yellow-dark: #ffb700;
    --kulhad: #D77051;
    --kulhad-dark: #c05535;
    /* secondary */
    --secondary: #091E42;
    --secondary-dark: #000000;
    /* tertiary */
    --tertiary-1: #85ffb6;
    --tertiary-2: #ffdb8c;
    --tertiary-3: #bca7ff;
    --tertiary-4: #ff8888;
    --tertiary-5: #ff74ff;
    --tertiary-dark-1: #188644;
    --tertiary-dark-2: #FFD372;
    --tertiary-dark-3: #7551DC;
    --tertiary-dark-4: #ff3939;
    --tertiary-dark-5: #ff6dff;
    --tertiary-light-1: #DAFFE9;
    --tertiary-light-2: #FAE9CF;
    --tertiary-light-3: #e7e0fc;
    --tertiary-light-4: #fecbcb;
    --tertiary-light-5: #ffdfff;
    /* form */
    --success-bg: #DAFFE9;
    --success-text: #188644;
    --failure-bg: #fecbcb;
    --failure-text: #ff3939;
    
    --card-white: rgb(241, 238, 238);
    /* other variables */
    --v-small-radius: 8px;
    --small-radius: 10px;
    --basic-radius: 20px;
    --large-radius: 30px;

    --thin-border: 0.5px solid var(--secondary-dark);
    --norm-border: 1.5px solid var(--secondary-dark);
    --thic-border: 2.5px solid var(--secondary-dark);
}

@font-face {
    font-family: Poppins-Regular;
    src: url(../font/Poppins-Regular.ttf);
}

@font-face {
    font-family: Poppins-Black;
    src: url(../font/Poppins-Black.ttf);
}

@font-face {
    font-family: Poppins-Thin;
    src: url(../font/Poppins-Thin.ttf);
}

@font-face {
    font-family: BobbyJones;
    src: url(../font/BobbyJones.otf);
}

@font-face {
    font-family: Stella;
    src: url(../font/Stella.ttf);
}

@font-face {
    font-family: Fragile;
    src: url(../font/fragile.otf);
}

.bobby {
    font-family: BobbyJones;
    font-weight: 400;
}

.stella {
    font-family: Stella;
    font-weight: 400;
}

.fragile {
    font-family: Fragile;
    font-weight: 400;
}

* {
    box-sizing: border-box;
    margin: 0px;
    font-family: Poppins-Regular;
    transition: all 200ms ease-in-out;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.page-contents {
    padding-top: 2vh;
}
    
.flex {
    display: flex;
}

.main-title-img {
    width: 50vw;
    margin: auto auto;
}

.yellow-box-title {
    font-size: 6vw;
    text-align: center;
    background-color: var(--yellow);
    width: fit-content;
    margin: 10px auto;
    padding: 0px 20px;
    border-radius: 100px;
}

.v-big-heading {
    margin: auto auto 5vh auto;
    font-size: 18vw;
    line-height: 240px;
    text-align: center;
    overflow: hidden;
    color: var(--yellow);
    text-shadow: 8px 8px 0px var(--secondary-dark);
    user-select: none;
}

.little-big-heading {
    font-size: 10vw;
    line-height: 120px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    user-select: none;
}

.big-heading {
    font-size: 56px;
    letter-spacing: 2px;
    text-align: center;
    user-select: none;
}

.main-btn {
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
    width: fit-content;
    margin: 20px auto;
    background-color: var(--yellow);
    box-shadow: 4px 4px 0px var(--kulhad);
    border: var(--norm-border);
    border-radius: var(--small-radius);
    user-select: none;
}

.main-btn:hover {
    padding: 10px 24px;
    box-shadow: 0px 0px 0px var(--failure-text);
}

.simple-heading {
    font-size: 36px;
}

.simple-sub-heading {
    font-size: 24px;
}

.small-text {
    font-size: 16px;
}

.margin-block-5 {
    margin-block: 5px;
}

.margin-block-10 {
    margin-block: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 40px;
}

.txt-ctr {
    text-align: center;
}

.bold {
    font-weight: 600;
}

.cur {
    cursor: pointer;
}

.chaas-memes {
    padding: 20px;
}

.cross-icon {
    float: right;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: var(--tertiary-dark-2);
    border-radius: var(--large-radius);
}

.top-arrow {
    position: fixed;
    right: 10px;
    bottom: 0px;
    padding: 5px 10px 0px 10px;
    color: var(--secondary-dark);
}

.top-arrow:hover {
    padding: 5px 10px;
}

@media (max-width: 550px) {
    .yellow-box-title {
        font-size: 10vw;
        background-color: var(--primary);
        box-shadow: none;
    }

    .main-title-img {
        width: 80vw;
    }

    .v-big-heading {
        line-height: 100px;
    }

    .big-heading {
        font-size: 38px;
    }

    .v-big-heading {
        text-shadow: 4px 6px 0px var(--secondary-dark);
        line-height: 100px;
    }
}

/* Scrollbar CSS */
/* width */
::-webkit-scrollbar {
    width: 8px !important;
    border-radius: var(--small);
    overflow: hidden;
}

/* handle */
::-webkit-scrollbar-thumb {
    background: var(--prim);
    border-radius: var(--basic);
}