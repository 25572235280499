.faq-section-parent {
    width: 60vw;
    margin: 10vh auto;
}

.faq-section {
    margin: auto auto;
}

.faq-question {
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    flex-wrap: wrap;
    height: fit-content;
    width: 98%;
    /* height: 50px; */
    font-size: 20px;
    padding-inline: 10px;
    margin: 10px auto;
    border: var(--norm-border);
    background-color: var(--primary);
    box-shadow: 4px 4px 0px var(--kulhad);
    outline: none;
    border-radius: var(--v-small-radius);
}

.plus-icon {
    font-weight: 600;
}

.answer {
    width: 100%;
    display: block;
    font-size: 16px;
    display: none;
    padding-top: 10px;
}

.visible {
    display: block;
}

@media (max-width: 1000px) {
    .faq-section-parent {
        width: 85vw;
    }
}

@media (max-width: 550px) {
    .faq-section-parent {
        width: 95vw;
    }
}