.reviews {
    width: 80vw;
    margin: 5vh auto;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.review {
    flex-grow: 1;
    flex-basis: 300px;
    width: 300px;
    min-height: 100px;
    padding: 10px;
    margin: 5px;
    box-shadow: 4px 4px 0px var(--kulhad);
    border: var(--norm-border);
    border-radius: var(--small-radius);
}

.review:hover {
    box-shadow: 4px 4px 0px var(--yellow);
}

.user-info {
    align-items: center;
}

.user-icon {
    padding: 5px;
    background-color: var(--yellow);
    border: var(--norm-border);
    border-radius: 50%;
    margin-right: 20px;
}

.user-name {
    font-size: 22px;
    line-height: 20px;
    overflow: hidden;
}

.profile-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 10px;
}

.review-details {
    flex: 1;
}

.review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 500px) {
    .reviews {
        width: 95vw;
    }
}