.marquee {
    position: relative;
    margin-block: 50px;
    padding: 10px;
    border-block: var(--thic-border);
}

.yellow-bg {
    background-color: var(--yellow);
}

.kulhad-bg {
    background-color: var(--kulhad);
}

.marquee-text {
    position: relative;
    left: -50%;
    font-size: 28px;
    color: var(--secondary-dark);
}

@media (max-width: 800px) {
    .marquee {
        padding: 5px;
    }

    .marquee-text {
        font-size: 20px;
    }
}