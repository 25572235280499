.shop-item-modal {
    position: fixed;
    top: 5vh;
    left: 5vw;
    width: 90vw;
    height: 90vh;
    background-color: var(--background);
    border-radius: var(--basic-radius);
    padding: 20px;
    box-shadow: 0px 0px 80px var(--secondary-dark);
    border: 4px solid var(--secondary-dark);
    overflow-y: scroll;
}

.cross-icon {
    float: right;
    padding: 4px;
}

.shop-item-modal .modal-img {
    display: flex;
    justify-content: center;
    width: 300px;
    margin: 20px auto;
    border-radius: var(--basic-radius);
}

.shop-item-modal .price {
    margin: 20px auto;
    gap: 20px;
    justify-content: center;
}

.shop-item-modal .price-box {
    padding: 5px 20px;
    margin: 20px 20px;
    background-color: var(--yellow);
    border-radius: var(--small-radius);
    border: 2px solid var(--secondary-dark);
    box-shadow: 0px 0px 10px var(--yellow);
}

.shop-item-modal .price-box:hover {
    box-shadow: 0px 0px 20px var(--yellow-dark);
}

.shop-item-modal .delivery-logos {
    width: 100%;
}