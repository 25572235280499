/* ---- navigation bar css ---- */
.nav {
    width: 96vw;
    margin: 5px auto;
    z-index: 10;
    overflow: visible;
}

nav {
    margin: auto auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--basic-radius);
}

/* ---- navigation bar links ---- */
.nav-items {
    height: fit-content;
    padding: 10px;
}

.logo {
    width: 250px;
}

.white {
    color: var(--white);
}

.nav-links {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    transition: all 400ms ease-in-out;
    cursor: pointer;
    gap: 15px;
}

.nav-div-name {
    display: flex;
    gap: 5px;
    align-items: center;
    margin: 10px;
    color: var(--secondary-dark);
    font-weight: 600;
    background-color: var(--yellow);
    margin: 10px;
    border-radius: var(--large-radius);
}

.dropdown-content {
    position: absolute;
    left: 0px;
    top: 100px;
    visibility: hidden;
    height: 0px;
    width: 98vw;
    margin: 0 10px;
    color: var(--primary);
    background-color: var(--primary);
    border-radius: var(--small-radius);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown {
    padding-inline: 5px;
}

.dropdown:hover .dropdown-content {
    visibility: visible;
    color: var(--secondary-dark);
    height: 50vh;
    padding-top: 20px;
    z-index: 100;
}

.what-icon {
    width: 30px;
}

.swig-icon {
    width: 21px;
}

.zom-icon {
    width: 28px;
}

/* navigation bar lines for collapsable nav bar */
.nav-lines {
    display: none;
    margin: auto 0;
}

.Bar {
    width: 30px;
    height: 4px;
    background-color: var(--yellow);
    box-shadow: 2px 2px 0px var(--secondary-dark);
    border-radius: 8px;
    margin: 5px 0;
}

a hr {
    width: 0%;
    border: 0.5px solid transparent;
    transition: 300ms ease-in-out;
}

.add-item {
    border: 1px dashed var(--black);
    border-radius: 8px;
    padding: 0px;
    margin: 5px;
}

.add-item div {
    padding: 10px 0px !important;
}

.add-nav-item {
    border: 2px dashed var(--white);
}

@media (max-width: 1050px) {
    .nav-lines {
        display: block;
        cursor: pointer;
    }

    .logo {
        width: 200px;
    }

    .nav-items {
        align-items: center;
        padding: 0px;
    }

    .nav-links {
        position: absolute;
        z-index: 3;
        background-color: var(--primary);
        box-shadow: 2px 2px 5px var(--primary-dark);
        margin: 0 0;
        display: block;
        top: 90px;
        left: 0vw;
        width: 97vw;
        height: 0;
        transform: 200ms;
        overflow: hidden;
        border-radius: 20px;
        overflow-y: hidden;
    }

    .nav-links.active {
        height: fit-content;
        padding: 10px;
        overflow: scroll;
    }

    .nav-div-name {
        padding: 15px;
    }

    .dropdown-content {
        display: none;
    }

    .dropdown-block {
        display: block;
    }

    .dropdown-content div {
        color: var(--white);
    }

    .nav-button .user-name {
        display: none;
    }
}