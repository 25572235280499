.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flashy-announcement {
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: var(--yellow);
    width: fit-content;
    margin: auto auto;
    padding: 10px 80px;
    border: var(--thic-border);
    border-style: dashed;
}

.main-img {
    width: 100vw;
    transform: translateY(-150px);
}

.main-para {
    font-size: 28px;
}

.first-marquee {
    transform: translateY(-210px);
}

.delivery-logos {
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100vw;
    margin: 10px auto;
    z-index: 10;
}

.delivery-logos img {
    width: 150px;
}

.section {
    margin-block: 10vh;
}

.story-text {
    width: 85vw;
    margin: 40px auto 0px auto;
    text-align: center;
    font-size: 20px;
}

.campus-delivery-home {
    gap: 40px;
    justify-content: center;
    align-items: center;
    margin: auto auto;
}

.campus-delivery-text {
    flex-direction: column;
    justify-content: left !important;
    font-size: 20px;
    width: 45vw;
}

.supporter-img-div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.supporter-img {
    width: 200px;
}

.talking-img {
    width: 45vw;
}

.numbers {
    gap: 60px;
    justify-content: center;
    flex-wrap: wrap;
    transform: translateY(-100px);
}

.metric {
    flex-grow: 1;
    flex-basis: 300px;
    font-family: BobbyJones;
}

.metric-name {
    font-family: BobbyJones;
    background-color: var(--yellow);
    padding: 0px 15px;
    border-radius: var(--large-radius);
}

.revolution-text-parent {
    width: 85vw;
    margin: 40px auto 0px auto;
    display: flex;
    gap: 60px;
    justify-content: space-between;
}

.revolution-text {
    font-size: 20px;
    text-align: justify;
}

.revolution-icons-parent {
    width: 85vw;
    margin: 40px auto 0px auto;
    justify-content: center;
    align-items: center;
}

.revolution-icon {
    min-width: 70px;
    height: 70px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    background-color: var(--secondary-dark);
}

.revolution-hr {
    min-width: 12vw;
    height: 0px;
    border: var(--thic-border);
    border-style: dashed;
}

.revolution-hr.white-hr {
    border-color: var(--primary);
}

.revolution-hr.black-hr {
    width: 100%;
}

.revolution-images {
    margin-top: 40px;
    margin-left: 40px;
    justify-content: center;
    gap: 8vw;
}

.revolution-images img {
    margin-top: 20px;
    animation: 5s ease infinite float-y;
}

.review-buttons {
    width: fit-content;
    padding-inline: 20px;
    margin: auto auto;
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

@keyframes float-y {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

@media (max-width: 1100px) {
    .main-img {
        transform: translateY(-12vw);
    }

    .first-marquee {
        transform: translateY(-190px);
    }

    .revolution-text-parent {
        width: 85vw;
    }

    .revolution-hr {
        min-width: 8vw;
    }

    .campus-delivery-text {
        font-size: 14px;
    }

    .campus-delivery-text .main-btn {
        font-size: 14px;
    }
    
    .talking-img {
        width: 45vw;
    }
}

@media (max-width: 800px) {
    .flashy-announcement {
        padding: 10px 20px;
    }

    .first-marquee {
        transform: translateY(-22vw);
    }

    .story-text {
        width: 90vw;
        font-size: 18px;
    }

    .delivery-logos {
        justify-content: center;
        gap: 10px;
    }

    .delivery-logos img {
        width: 100px;
    }

    .campus-delivery-home {
        flex-direction: column;
    }
    
    .talking-img {
        width: 400px;
    }

    .campus-delivery-text {
        width: 80vw;
        text-align: center;
    }

    .revolution-hr {
        min-width: 6vw;
    }

    .revolution-images {
        gap: 2vw;
    }

    .revolution-text-parent {
        width: 95vw;
    }

    .revolution-text {
        font-size: 12px;
    }
}

@media (max-width: 550px) {
    .main-img {
        margin-top: 40px;
    }

    .first-marquee {
        transform: translateY(-24vw);
    }

    .story-text {
        font-size: 14px;
        text-align: left;
    }

    .campus-delivery-home {
        gap: 10px;
    }

    .talking-img {
        width: 80vw;
    }

    .revolution-icon {
        min-width: 50px;
        height: 50px;
        font-size: 30px;
    }

    .revolution-text-parent {
        flex-direction: column;
        width: 85vw;
        gap: 25px;
    }
    
    .revolution-text {
        font-size: 14px;
        text-align: left;
        width: 100%;
    }

    .revolution-flex {
        display: flex;
        flex-direction: row;
    }

    .revolution-icons-parent {
        flex-direction: column;
        width: 120px;
        margin-inline: 10px;
    }
    
    .revolution-icon {
        width: 70px;
        height: 70px;
    }

    .revolution-hr {
        width: 0px !important;
        height: 30vh;
    }

    .revolution-hr.black-hr {
        min-width: 0px;
    }

    .revolution-hr.white-hr {
        height: 0px;
    }

    .revolution-images {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .revolution-images img {
        width: 200px;
        margin-block: 20px;
    }

    .review-buttons .main-btn {
        margin: 10px;
    }

    .flashy-announcement {
        padding: 10px;
    }

    .flashy-announcement .main-para {
        font-size: 16px;
    }

    .flashy-announcement .offer-para {
        font-size: 12px;
    }

    .flashy-announcement img {
        width: 60px;
    }
}

@media (max-width: 400px) {
    .first-marquee {
        transform: translateY(-28vw);
    }

    .review-buttons {
        gap: 0px;
    }

    .flashy-announcement {
        padding: 10px;
        margin: auto;
    }
}