/* Footer Section */
.footer {
    width: 100%;
    margin: 30px auto 0px auto;
    padding: 70px 30px 10px 30px;
    background-color: var(--secondary-dark);
    list-style: none;
    background: url(../../public/images/footer-img.png);
    background-size: cover;
}

.footer-sections {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 90%;
    margin: auto auto;
}

.foot-section {
    min-width: 120px;
    margin: 20px 0;
}

.foot-section>li>a, .foot-section>li>b {
    margin: 5px 0;
    display: block;
    font-weight: 600;
    color: var(--secondary-dark);
    text-decoration: none;
}

.foot-section>.heading {
    padding: 0 0 15px 0;
    font-size: 24px;
    font-weight: 600;
}

.footer-logo {
    width: 300px;
}

.below-footer-links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    width: 90%;
    margin: auto auto;
    color: var(--white);
}

.below-footer-links li a {
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
    padding: 0px;
}

.below-footer-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 600;
}

.below-footer-section a {
    font-size: 18px;
    align-items: center;
    margin: 10px;
    text-decoration: none;
}

.privacy-policy {
    display: flex;
    justify-content: center;
}

.privacy-policy li a {
    margin: 10px;
}

.footer-hr {
    border: var(--thin-border);
}

@media (max-width: 1000px) {
    .foot-section {
        min-width: 200px;
        padding-left: 40px;
    }

    .footer-logo {
        width: 200px;
    }
}

@media (max-width: 600px) {
    .foot-section {
        padding-left: 0px;
    }

    .footer {
        background-image: none;
        background-color: var(--secondary-dark);
    }

    .foot-heading {
        color: var(--yellow);
    }

    .foot-section li a, .foot-section li b {
        color: var(--primary);
    }
}

.yellow-text {
    color: var(--yellow);
    text-shadow: 2px 2px 2px var(--secondary-dark);
    font-weight: 600;
}

.valueye-section {
    padding-block: 10px;
    padding-inline: 100px;
    
    /* color: var(--white); */
    /* background-color: var(--secondary-dark); */
}



.valueye-logo {
    width: 200px;
    margin-top: 10px;
}