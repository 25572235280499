.product-img {
    height: 40vh;
    border-radius: var(--small-radius);

}

.grid-item {
    margin-top: 5px;
    margin-bottom: -2px;
}

.product-grid {
    height: 40vh;
    border-radius: var(--small-radius) !important;
}

.hover-button {
    margin-top: 50px;
}